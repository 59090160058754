.rdw-image-imagewrapper > img {
  height: 200px !important;
}

.rdw-image-alignment {
  display: flex;
  justify-content: flex-start;
}

.btn-disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-disabled:focus {
  outline: 0;
  box-shadow: 0 0 0 0rem rgb(0 0 0 / 25%) !important;
}

.btn-disabled:hover {
  color: white;
}
